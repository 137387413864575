
	import {
		ElMessage
	} from 'element-plus';
	import {setCookie} from '../util/index.js';
	export default {
		data() {
			return {
				loading: false,
				logingin: true,
				registing: false,
				changepass: false,
				Login: {
					Account: '',
					Passwd: ''
				}
			}
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.Login;
		},
		methods: {
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				return year + "-" + month + "-" + day;
			},
			DoLogin() {
				if(!this.Login.Account || !this.Login.Passwd) {
					ElMessage({
						message: this.PAGELANG.qsryxdlzhhmm,
						type: "warning"
					});
				}
				
				//登录
				this.loading = true;
				this.axios.post(this.actions.loginurl, this.Login).then((response) => {
					console.log(response);
					setCookie('logintoken', response.data.token, 30);
					setCookie('loginopid', response.data.opid, 30);
					setCookie('roleid', response.data.roleid, 30);
					if(response.data.storeid)
						setCookie('storeid',response.data.storeid, 30);
					let that = this;
					if (response.data.retinfo == 'OK') {
						ElMessage({
							message: this.PAGELANG.dengluchenggong,
							type: "success",
							duration: 500,
							onClose: function () {
								that.$router.push('/');
							}
						});
					}
					else {
						ElMessage({
							message: this.PAGELANG.wfdlqqrzhmmzqzcs,
							duration: 500,
							type: "error"
						});
					}
					this.loading = false;
				});
			}
		},
		created() {},
		mounted() {
		},
		components: {}
	}
